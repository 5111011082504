.pagination-action {
  color: #BDBEC0;
  display: flex;
  align-items: center;
  height: auto;
  font-size: 1rem;

    &__double-arrow {
      height: 12px;
      width: auto;
    }
    &__arrow {
      height: 12px;
    }
    &__more-horiz {
      top: 5px;
      padding: 0 !important;
    }
    span {
      margin: 7px;
      cursor: pointer;
      &:hover {
        color: grey;
      }
    }
    img {
      cursor: pointer;
    }
    .p0 {
      padding: 0;
    }
}
