$pie_width: 170px;
$pie_height: $pie_width/2;
$wrapper-bg:  #c9cfd6;
$yellow: #FDBB2D;
$red: #FE7153;
$text-color: #191970;
$body-bg: #fff;
$font-family: Montserrat, sans-serif;

.chart-container {
   display: inline-block;
  position: relative;
  margin: 10px 0 30px;

  &--red {
    .pie-wrapper {
      .arc {
        border-color: $red;
      }
    }
  }

  .label {
    color: $text-color;
    font-family: $font-family;
    display: block;
    position: absolute;
    bottom: -20px;
    font-size: 12px;
    text-align: center;
    width: 30px;
    &--from {
      left: 0;
    }
    &--to {
      right: 0;
    }
  }
}
.pie-wrapper{
	position: relative;
  width:$pie_width;
  height:$pie_height;
  overflow:hidden;

  .arc, &:before{
  content: '';
  width: $pie_width;
  height: $pie_height;
  position: absolute;
  -ms-transform-origin: 50% 0%;
  -webkit-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
  left: 0;
  box-sizing: border-box;
  }

  &:before{
  border: 30px solid $wrapper-bg;
  border-bottom: none;
  top: 0;
  z-index: 1;
  border-radius: 200px 200px 0 0;
  }

  .arc{
    border: 30px solid $yellow;
    border-top: none;
    border-radius: 0 0 200px 200px;
    top: 100%;
    z-index: 2;
    animation: fill 2s;
    transition:All 5s ease;
    &:after{
      content: '';
      position: absolute;
      left:  -40px;
      top: 5px;
    }
  }

  .score {
    color: $text-color;
    font-family: $font-family;
    font-size: 18px;
    display: block;
    width: $pie-width;
    text-align: center;
    margin-top: ($pie-height/2) + 10;
  }
}

@for $i from 1 through 100 {
  .arc[data-value="#{$i}"]{
    transform: rotate(#{$i * 1.8}deg);
  }
}

@keyframes(fill){
  0%{
    transform: rotate(0deg);
  }
  50%{
    transform: rotate(180deg);
  }
}
