.custom-checkbox {
  position: absolute; // take it out of document flow
  opacity: 0; // hide it

  & + label {
    display: block;
    position: relative;
    cursor: pointer;
    padding: 0 0 0 30px;
    text-align: left;
  }

  // Box.
  & + label:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    margin-right: 10px;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    background-color: #e1edfb;
  }

  // Box checked
  &:checked + label:before {
    background-image: url("./icon-check-mark.svg");
    background-repeat: no-repeat;
    background-position: center center;
  }
}
